<template>
	<div>
		<div class="box" v-if="table">
			<CustomTable
				v-if="contractIds !== null"
				id_table="contract"
				:busy.sync="table_busy"
				primaryKey="contract_id"
				:hide_if_empty="true"
				:rawColumns="rawColumnNames"
				:hrefsRoutes="config_table_hrefs"
				:base-filters="filters"
				:transformer="['ContractTransformer', 'withAllAvenantArticles']"
				ref="contractTable"
				:externSlotColumns="extern_slot_columns"
				:columsAddBegin="['preview']"
			>
				<template v-slot:[`custom-slot-cell(preview)`]="{ data }">
					<a href="" class="" @click.prevent="quickContractPreview(data.contract_id)">
						<font-awesome-icon :icon="['fas', 'eye']" />
					</a>
				</template>
			</CustomTable>
		</div>
		<div v-else>
			<ContractShutter :contract_id="contract_id" from="tiersList"></ContractShutter>
		</div>

		<b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

		<b-modal ref="modalEnvoie" hide-footer>
			<template v-slot:modal-title>
				{{ $t("courrier_type.ajout") }}
			</template>

			<e-select
	            v-model="model_selected"
	            id="models"
	            track-by="model_label"
	            label="model_label"
	            class="primary"
	            :options="models"
	            :searchable="true"
	            :show-labels="false"
	            :allow-empty="false"
	        >
	            <template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
	            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
	        </e-select>

	        <div v-if="model_selected" class="text-center">
	            <b-button pill variant="primary" class="mt-3" @click.prevent="addModelCourrier"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("courrier_type.choisir") }}</b-button>
	        </div>
		</b-modal>

		<ModalContractModifRapide ref="modal_contract_modif_rapide"/>
		<ModalSendContract ref="modal_send_contract" @submit="refreshTable"/>
		<ModalActDGLot ref="modal_act_dg_lot" @submit="refreshTable"/>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import Common from '@/assets/js/common'
	import Model from "@/mixins/Model.js"

	export default {
		name: "ContratTiersCourtier",
		mixins: [TableAction, Navigation, ContractMixin, Model],
		props: ['tiers'],
		data () {
			return {
				table_busy: false,
				base64: null,
				table: true,
				contract_id: null,
				contractIds: null,
				model_selected: {},
				models: [],
				processing: false,
				events_tab: {
					'TableAction::goToAddContract': async () => {
						this.contract_id = await this.addContractOnlineFromTiers(this.tiers.tiers_id);
						this.table = false
					},
					'TableAction::goToEditContract': (params) => {
						this.contract_id = parseInt(params.contract_id);
						this.table = false
					},
					'TableAction::goToPrintContract': (contract_ids) => {
						this.callPrintContracts(contract_ids)
					},
					'TableAction::goToDeleteContract': (contract_ids) => {
						this.deleteContract(contract_ids)
					},
					'TableAction::goToFastEditContract': (contract) => {
						this.openModalFastEditContract(contract[0])
					},
					'TableAction::goToMarkContractAsSended': (contract_ids) => {
						this.markAsSended(contract_ids)
					},
					'TableAction::goToSendContract': (contract_ids) => {
						this.openModalSendContract(contract_ids)
					},
					'TableAction::goToActDgLotContract': (contracts) => {
						this.openModalActDGLot(contracts)
					},
					'TableAction::goToReindexContract': (contracts) => {
						this.reindexContract(contracts)
					},
					'TableAction::goToCourrierContract': (contract_ids) => {
						this.callCourrierContracts(contract_ids)
					}
				},
				rawColumnNames: [
					"articles",
					"seasonmarestallion.seasonmarestallion_status",
					"contract_note",
					"avenant.avenant_justification",
					"avenant.avenant_comment"
				],
				config_table_hrefs: {
					'contract_num': {
						routeUniqueName: 'ContractFormv2',
						routeName: 'ContractFormv2',
						params: {
							contract_id: 'contract_id'
						}
					},
					'contract_tiers[0].tiers.tiers_rs': {
						routeUniqueName: 'tiersTableauBord',
						routeName: 'tiersTableauBord',
						params: {
							tiers_id: 'contract_tiers.0.tiers.tiers_id'
						}
					},
					'avenant.horse.horse_nom': {
						routeUniqueName: 'MareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'avenant.horse.horse_id'
						}
					},
					'config.horse.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'config.horse.horse_id'
						}
					},
					'avenant.cmep.tiers_rs': {
						routeUniqueName: 'cmepFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'avenant.cmep.tiers_id'
                        }
                    },
					'courtier.tiers_rs': {
						routeUniqueName: 'courtierFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'courtier.tiers_id'
                        }
                    }					
				},
				extern_slot_columns: [
					'preview'
				]
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.table_busy = true

				this.contractIds = await this.loadContractsCourtier(this.tiers.tiers_id)

				this.table = true
				this.contract_id = null
			},
			async checkForm() {
				if(!this.processing) this.processing = true
			},
			async callPrintContracts(contract_ids) {
				await Common.asyncForEach(contract_ids, async (contract_id) => {
					let result = await this.printContract(parseInt(contract_id))
					if(result == null){
						this.failureToast('monte.print_error')					
					}
					EventBus.$emit("TableAction::stopSpin")
				})
			},
			async deleteContract(contract_ids) {
				try {
					await this.deleteContracts(contract_ids)
				}
				catch {
					EventBus.$emit('App::failureToast', 'error.DC')
				}

				this.$refs.contractTable.refreshTable()
			},
			openModalFastEditContract(contract) {
				let contract_avenant = contract.avenant
				let infos_contract = {
					contract_id: contract.contract_id,
					contract_note: contract.contract_note,
					contractconfig_horse: contract.config.contractconfig_horse,
					contractconfig_season: contract.config.contractconfig_season
				}
				let infos_avenant = {
					avenant_id: contract_avenant.avenant_id, 
					avenant_dn: contract_avenant.avenant_dn,
					avenant_dn_bool: contract_avenant.avenant_dn_bool,
					avenant_dps: contract_avenant.avenant_dps, 
					avenant_as_bool: contract_avenant.avenant_as_bool, 
					avenant_date_signature: contract_avenant.avenant_date_signature, 
					avenant_date_validation: contract_avenant.avenant_date_validation, 
					avenant_status: contract_avenant.avenant_status,
					avenant_type_contrat: contract_avenant.avenant_type_contrat,
					avenant_comment: contract_avenant.avenant_comment
				}
				this.$refs.modal_contract_modif_rapide.openModal(infos_contract, infos_avenant)
			},
			async markAsSended(contract_ids) {
				await this.markSendedContract(contract_ids)
				.then(() => {
					this.$refs.contractTable.refreshTable()
				})
			},
			openModalSendContract(contract_ids) {
				this.$refs.modal_send_contract.openModal(contract_ids)
			},
			refreshTable() {
				EventBus.$emit("TableAction::stopSpin")
				this.$refs.contractTable.refreshTable()
			},
			openModalActDGLot(contracts) {
				this.$refs.modal_act_dg_lot.openModalContract(contracts)
			},
			async reindexContract(contracts) {
				const contract_id = contracts.map(contract => contract.contract_id)
				await this.forceIndexationContract(contract_id)
				this.$refs.contractTable.refreshTable()
			},
			async callCourrierContracts(contract_ids) {
                const list_type = ['contract_envoie', 'contract_envoie_adresse']
                this.models = await this.loadAllModel(0, list_type)
                this.contracts_selected = contract_ids
                this.$refs.modalEnvoie.show()
				EventBus.$emit("TableAction::stopSpin")
			},			
			async addModelCourrier() {
				this.processing = true
				for(let i in this.contracts_selected) {
					const model = await this.createModel(this.contracts_selected[i], this.model_selected.type.modeltype_id, this.model_selected.model_label, 'contract_envoie')
					await this.cloneModel(this.model_selected.model_id, model.model_id)
					await this.generateCourrier(model.model_id, this.model_selected.type.modeltype_template, this.contracts_selected[i])
					await this.printCourrier(model.model_id, this.model_selected.model_label + "_" + this.contracts_selected[i])
				}
				this.processing = false
                this.$refs.modalEnvoie.hide()
			},
			async quickContractPreview(contract_id) {
                this.$refs.modelPreview.show()
                let retour = await this.printContract(contract_id, true)
                if(retour) {
                    this.base64 = retour
                }
            }
		},
		computed: {
			filters() {
				return {
					is_in_group: {
						column: 'contract_id',
						operator: 'isInList',
						value: this.contractIds
					}
				}
			}
		},
		watch: {
			$route(){
				this.table = true
			}
		},
		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ContractShutter : () => import('@/components/Contract/ContractShutter'),
			ModalContractModifRapide: () => import('@/components/Contract/ModalContractModifRapidev2'),
			ModalSendContract: () => import('@/components/Contract/ModalSendContract'),
			ModalActDGLot : () => import('@/components/Gynecologie/ModalActDGLot'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		},
	}
</script>
